import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import MainView from "@/views/Main.vue";
import NushMeet from "@/nushmeet/Main.vue";
import NotesView from "@/views/Notes.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: MainView,
  },
  {
    path: "/notes",
    component: NotesView,
  },
  {
    path: "/meet",
    component: NushMeet,
  },
];

export default new VueRouter({
  routes,
});
