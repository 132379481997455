import {Weekdays} from "@/nushmeet/types/MgTimetable";
import {Teachers, Timetable} from "@/nushmeet/api";
import Lesson from "@/nushmeet/types/Lesson";

export type STET = [number, number]

function getStEt(lesson: Lesson): STET {
  return [lesson.start_time, lesson.end_time];
}

function uniqueSorted(times: STET[]): STET[] {
  const out: STET[] = [];
  for (const stet of times) {
    if (out.some(s => s[0] == stet[0] && s[1] == stet[1])) {
      continue;
    }
    out.push(stet);
  }
  return out.sort((a, b) => (a[0] - b[0]) * 10000 + (a[1] - b[1]));
}

function uniqueMerge(stuff: DetailedLesson[]): DetailedLesson[] {
  const out: DetailedLesson[] = [];
  for (const item of stuff) {
    const index = out.findIndex(s => s.start == item.start);
    if (index > -1) {
      out[index].mg.push(...item.mg);
      continue;
    }
    // Clone just in case
    out.push(Object.assign({}, item));
  }
  return out;
}

function mergeTimes(times: STET[]): STET[] {
  times = uniqueSorted(times);
  if (times.length < 2) {
    return times;
  }
  const out = [times[0]];
  for (let i = 1; i < times.length; i++) {
    const [st, et] = times[i];
    if (st == out[out.length - 1][1]) {
      out[out.length - 1] = [out[out.length - 1][0], et];
    } else {
      out.push([st, et]);
    }
  }
  return out;
}

export function getLessons(timetable: Timetable, mg: string, subject: string): Map<Weekdays, STET[]> {
  const out = new Map<Weekdays, STET[]>();
  const data = timetable[mg];
  for (const day in data) {
    const key = day as Weekdays;
    for (const lesson of data[key]) {
      if (lesson.subject.includes(subject)) {
        if (out.has(key)) {
          out.get(key)?.push(getStEt(lesson));
        } else {
          out.set(key, [getStEt(lesson)]);
        }
      }
    }
  }
  return out;
}

export type DetailedLesson = { subject: string; mg: string[]; start: number; end: number }

export function getTeacherLessonsDetailed(teachers: Teachers, timetable: Timetable, teacher: string): Map<Weekdays, DetailedLesson[]> {
  const out = new Map<Weekdays, DetailedLesson[]>();
  for (const mg in teachers.data) {
    const entries = Object.entries(teachers.data[mg]);
    for (const [subject, tcs] of entries) {
      if (!tcs.includes(teacher)) {
        continue;
      }
      const lessons = getLessons(timetable, mg, subject);
      const keys = Array.from(lessons.keys());
      for (const day of keys) {
        if (out.has(day)) {
          const dayLessons = lessons.get(day)?.map(lesson => {
            return {
              subject,
              mg: [mg],
              start: lesson[0],
              end: lesson[1],
            };
          }) ?? [];
          out.get(day)?.push(...dayLessons);
          out.set(day, uniqueMerge(out.get(day) ?? []));
        } else {
          out.set(day, lessons.get(day)?.map(lesson => {
            return {
              subject,
              mg: [mg],
              start: lesson[0],
              end: lesson[1],
            };
          }) ?? []);
        }
      }
    }
  }
  return out;
}

export function getTeacherLessons(teachers: Teachers, timetable: Timetable, teacher: string) {
  const out = new Map<Weekdays, STET[]>();
  for (const mg in teachers.data) {
    const entries = Object.entries(teachers.data[mg]);
    for (const [subject, tcs] of entries) {
      if (!tcs.includes(teacher)) {
        continue;
      }
      const lessons = getLessons(timetable, mg, subject);
      const keys = Array.from(lessons.keys());
      for (const day of keys) {
        if (out.has(day)) {
          const dayLessons = lessons.get(day) ?? [];
          out.get(day)?.push(...dayLessons);
          out.set(day, mergeTimes(out.get(day) ?? []));
        } else {
          out.set(day, lessons.get(day) ?? []);
        }
      }
    }
  }
  for (const day of ["Monday",
    "Wednesday",
    "Friday",
    "Tuesday",
    "Thursday",
  ]) {
    if(!out.has(day as Weekdays)){
      out.set(day as Weekdays, []);
    }
  }
  return out;
}

function breaks(timetable: Timetable, lessons: Lesson[]) {
  const out: STET[] = [];
  for (const lesson of lessons) {
    if (["Recess", "Break", "Lunch"].includes(lesson.subject[0])) {
      out.push(getStEt(lesson));
    }
  }
  return mergeTimes(out);
}

export function breakMg(timetable: Timetable, mg: string) {
  const out = new Map<Weekdays, STET[]>();
  for (const day in timetable[mg]) {
    out.set(day as Weekdays, breaks(timetable, timetable[mg][day as Weekdays]));
  }
  return out;
}

export function invert(times: STET[]) {
  times = uniqueSorted(times);
  const out: STET[] = [];
  let startTime = 800;
  const endTime = 1800;
  for (const time of times) {
    if (time[0] - startTime > 0) {
      out.push([startTime, time[0]]);
    }
    startTime = time[1];
  }
  if (startTime != endTime) {
    out.push([startTime, endTime]);
  }
  return out;
}


export function intersect(times1: STET[], times2: STET[]) {
  times1 = uniqueSorted(times1);
  times2 = uniqueSorted(times2);
  let i2 = 0;
  let i1 = 0;
  const out: STET[] = [];
  while (i1 < times1.length && i2 < times2.length) {
    const [t1, t2] = [times1[i1], times2[i2]];
    if (t2[0] < t1[1] && t1[0] < t2[1]) {
      out.push([Math.max(t1[0], t2[0]), Math.min(t1[1], t2[1])]);
    }
    if (t2[1] < t1[1]) {
      i2++;
    } else {
      i1++;
    }
  }
  return out;
}

export function expand0X(mg: string) {
  if (mg[2].toLowerCase() == "x") {
    const out = [];
    const numClasses = parseInt(mg[0]) < 3 ? 6 : 7;
    for (let i = 1; i <= numClasses; i++) {
      out.push("M21" + mg[0] + "0" + i.toString());
    }
    return out;
  }
  return ["M21" + mg];
}
