










































import Vue from "vue";
import Party from "@/nushmeet/components/Party.vue";
import {fetchTeachers, fetchTimetables} from "@/nushmeet/api";
import {breakMg, expand0X, getTeacherLessons, intersect, invert, STET} from "@/nushmeet/algs";
import "@/nushmeet/style.css";
import Timetable from "@/nushmeet/components/Timetable.vue";
import {Weekdays} from "@/nushmeet/types/MgTimetable";

export default Vue.extend({
  name: "NushMeet",
  components: {Party, Timetable},
  data: function () {
    return {
      partyData: [{type: "teacher", data: null}],
      timetable: null,
      teachers: null,
      disclaimer: false,
    };
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      window.location.href = "/nushmeet/auth.html";
    }
    window.document.title = "NUSH Meet";
    fetchTimetables().then(timetable => {
      this.$data.timetable = timetable;
    });
    fetchTeachers().then(teachers => {
      this.$data.teachers = teachers;
    });
  },
  methods: {
    deleteParty(party: number) {
      console.log(party);
      this.$data.partyData.splice(party, 1);
      console.log(this.$data.partyData);
    }
  },
  computed: {
    results() {
      if (this.$data.timetable == null || this.$data.teachers == null) {
        return null;
      }
      if (this.$data.partyData.some((it: any) => !it.data || it.data.length == 0)) {
        return null;
      }
      const breaks = this.$data.partyData.map((partyData: { type: "teacher" | "class"; data: any }) => {
        if (partyData.type == "teacher") {
          return [new Map(Array.from(getTeacherLessons(this.$data.teachers, this.$data.timetable, partyData.data))
            .map(([key, val]) => [key, invert(val)]))];
        } else {
          return expand0X(partyData.data).map((mg: string) => breakMg(this.$data.timetable, mg));
        }
      }).flat();
      if (breaks.length == 1) {
        return false;
      }
      const out: { [day: string]: any } = {};
      for (const day of Array.from(breaks[0].keys())) {
        const key = day as Weekdays;
        if (breaks.some((breakDay: Map<Weekdays, STET[]>) => !breakDay.has(key))) {
          continue;
        }
        out[day as string] = breaks[0].get(day);
        breaks.slice(1).forEach((breaks: Map<Weekdays, STET[]>) => {
          out[day as string] = intersect(out[day as string], breaks.get(key) ?? []);
        });
        out[day as string] = out[day as string].map((interval: STET) => {
          return {
            subject: [interval[0] + " to " + interval[1]],
            start_time: interval[0],
            end_time: interval[1],
          };
        });
      }
      return out;
    }
  }
});
