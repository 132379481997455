






















































import Vue from "vue";
import {fetchTeachers, fetchTimetables} from "@/nushmeet/api";
import MgTimetable, {Weekdays} from "@/nushmeet/types/MgTimetable";
import "@/nushmeet/style.css";
import {getTeacherLessonsDetailed} from "@/nushmeet/algs";


export default Vue.extend({
  name: "Timetable",
  props: {
    mg: String,
    lessons: Object,
    teacher: String,
  },
  data: function () {
    return {
      timetable: null,
      teachers: null,
      today: new Date("2021-01-11"),
      moreInfo: {
        show: false,
        x: 0,
        y: 0,
        event: null,
      }
    };
  },
  mounted() {
    fetchTimetables().then(timetable => {
      this.$data.timetable = timetable;
    });
    fetchTeachers().then(teachers => {
      this.$data.teachers = teachers;
    });
  },
  methods: {
    getDate(day: number) {
      return "2021-01-1" + day;
    },
    getColor(name: string) {
      const mapping: { [subject: string]: string } = {
        "BL": "#D52D57",
        "CM": "#AEB518",
        "PC": "#32ADA5",
        "CS": "#97D989",
        "EL": "#FC79A6",
        "DV": "#FFDC86",
        "MA": "#FFB92E",
        "HU": "#FF6806",
        "PE": "#EAD1DC",
        "AR": "#F7ABFF",
        "MU": "#F972FF",
        "HY": "#E018FF",
        "Assembly": "#D9EAD3",
        "WS": "#155BB3",
        "3rd Lang": "#00FFFF",
      };
      if (["Break", "Lunch", "Recess"].includes(name)) {
        return "black";
      }
      if (name in mapping) {
        return mapping[name];
      }
      if (name.endsWith("-H")) {
        return mapping[name.replace("-H", "")];
      }
      return "#ffffff";
    },
    getTextColor(name: string) {
      const color = this.getColor(name).substring(1);
      const r = parseInt(color.substr(0, 2), 16);
      const g = parseInt(color.substr(2, 2), 16);
      const b = parseInt(color.substr(4, 2), 16);
      const rgb = [r, g, b];
      if (color == "black") {
        return "light-blue lighten-5";
      }
      const brightness = Math.round(((rgb[0] * 299) +
        (rgb[1] * 587) +
        (rgb[2] * 114)) / 1000);
      return brightness > 125 ? "black" : "white";
    },
    formatDate(date: { weekday: number }) {
      const base = ["Monday",
        "Tuesday",
        "Wednesday",
        "Thursday", "Friday"][date.weekday - 1];
      if (screen.width < 700) {
        return base.substring(0, 3);
      }
      return base;
    },
    showEvent(event: any, mouse: MouseEvent) {
      if (!this.$props.mg) return;
      this.$data.moreInfo.show = true;
      this.$data.moreInfo.x = mouse.clientX;
      this.$data.moreInfo.y = mouse.clientY;
      const teachersData = this.$data.teachers ? this.$data.teachers.data[this.$props.mg] : {};
      event.teachers = event.name in teachersData ? teachersData[event.name]
        .map((name: string) => this.$data.teachers.mapping[name]).join(" / ") : "None/Unknown";
      this.$data.moreInfo.event = event;
      mouse.preventDefault();
    },
    mergeMgs(mgs: string[]): string {
      if (mgs.length == 1) return mgs[0];
      if (mgs[0].substring(0, 5) == mgs[1].substring(0, 5)) {
        return mgs[0].substring(0, 5) + "[" + mgs.map(mg => mg.substring(5)).join("/") + "]";
      }
      return mgs.join("/");
    }
  },
  computed: {
    event() {
      if (this.$data.timetable == null || this.$data.teachers == null) {
        return [];
      }
      const dayToNum = (day: Weekdays) => ["Monday",
        "Tuesday",
        "Wednesday",
        "Thursday", "Friday"].indexOf(day) + 1;
      const numToTime = (num: number) => Math.floor(num / 100) + ":" + num % 100;
      let timetable: MgTimetable | null = this.$props.mg == null ? this.$props.lessons : this.$data.timetable[this.$props.mg];
      if (timetable == null && this.$props.teacher) {
        const lessons = getTeacherLessonsDetailed(this.$data.teachers, this.$data.timetable, this.$props.teacher);
        timetable = {} as MgTimetable;
        for (const [key, val] of Array.from(lessons.entries())) {
          timetable[key] = val.map(interval => ({
            subject: [interval.subject + "  with " + this.mergeMgs(interval.mg)],
            start_time: interval.start,
            end_time: interval.end,
          }));
        }
      }
      const out: any[] = [];
      for (const day in timetable) {
        const key = day as Weekdays;
        for (const lesson of timetable[key]) {
          for (const subject of lesson.subject.toString().split(",")) {
            out.push({
              name: subject.trim(),
              start: this.getDate(dayToNum(key)) + " " + numToTime(lesson.start_time),
              end: this.getDate(dayToNum(key)) + " " + numToTime(lesson.end_time),
            });
          }
        }
      }
      return out;
    }
  }
});
