










import Vue from "vue";

export default Vue.extend({
  name: "ClassSelect",
  props:{
    mg:String,
  },
  methods: {
    validateClass(mg: string) {
      if (!mg) {
        return false;
      }
      if (mg.length != 3) {
        return "Mentor Group must be 3 characters";
      }
      if (mg[1] != "0") {
        return "Mentor Group must be of the form [1-6]0[1-7X]";
      }
      const year = parseInt(mg[0]);
      if (Number.isNaN(year) || year > 6 || year < 1) {
        return "Year must be an integer from 1-6";
      }
      const classNo = parseInt(mg[2]);
      if ((Number.isNaN(classNo) || classNo > 7 || classNo < 1) && mg[2].toLowerCase() !== "x") {
        return "Class number must be an integer from 1-7";
      }
      if (year < 3 && classNo == 7) {
        return "Class number must less than 7 for Year 1 and 2";
      }
      return true;
    },
  }
});
