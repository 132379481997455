











import Vue from "vue";
import {fetchTeachers} from "@/nushmeet/api";

export default Vue.extend({
  props: {
    teacher: String,
  },
  name: "TeacherSelect",
  data: function () {
    return {
      teachers: [],
      random: null,
    };
  },
  methods: {
    filter(item: { name: string; code: string }, queryText: string) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.code.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1;
    },
    randomTeacher() {
      const random = Math.floor(Math.random() * this.teachers.length);
      this.$data.random = this.$data.teachers[random].name;
    }
  },
  mounted() {
    fetchTeachers().then(({data: _, mapping}) => {
      const out = [];
      for (const code in mapping) {
        out.push({
          code,
          name: mapping[code],
          id: code
        });
      }
      return out;
    }).then(data => {
      this.$data.teachers = data;
      this.randomTeacher();
    });
  }
});
