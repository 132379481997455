

















































import Vue from "vue";
import TeacherSelect from "@/nushmeet/components/TeacherSelect.vue";
import ClassSelect from "@/nushmeet/components/ClassSelect.vue";
import Timetable from "@/nushmeet/components/Timetable.vue";

export default Vue.extend({
  name: "Party",
  components: {ClassSelect, TeacherSelect, Timetable},
  props: {
    n: Number,
    deletable: Boolean,
    type: String,
    data: String,
  },
  data: function () {
    return {
      showTimetable: false
    };
  },
});
