import MgTimetable from "@/nushmeet/types/MgTimetable";
import MgTeachers from "@/nushmeet/types/MgTeachers";

export type Timetable = {
  [mg: string]: MgTimetable;
}

export type Teachers = {
  data: {
    [mg: string]: MgTeachers;
  };
  mapping: {
    [code: string]: string;
  };
}

const cache = new Map<string, Teachers | Timetable>();

const apiUrl = "https://asia-southeast2-hwboard-3e628.cloudfunctions.net/nush-auth-general";


export async function fetchTimetables(): Promise<Timetable> {
  const token = localStorage.getItem("token");
  if (cache.has("timetable")) {
    return cache.get("timetable") as Timetable;
  }
  const data = await (await fetch(apiUrl + "?file=timetable.json&token="+token)).json();
  cache.set("timetable", data.data);
  return data.data;
}

export async function fetchTeachers(): Promise<Teachers> {
    const token = localStorage.getItem("token");
  if (cache.has("teachers")) {
    return cache.get("teachers") as Teachers;
  }
  const data = await (await fetch(apiUrl + "?file=teachers.json&token="+token)).json();
  cache.set("teachers", data);
  return data;
}
