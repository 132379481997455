



















import Vue from "vue";

export default Vue.extend({
  name: "MainView",
  data: function () {
    return {
    };
  },
  methods: {},
});
