



































import Vue from "vue";

export default Vue.extend({
  name: "NotesView",
  data: function () {
    return {
      subjects: new Map([
          ["CS", "CS"],
          ["PC", "Physics"],
          ["CM", "Chemistry"],
          ["MA", "Mathematics"]]
        // ["EL", "English"]]
      ),
      icons: new Map([
        ["CS", "xml"],
        ["PC", "atom"],
        ["CM", "flask-outline"],
        ["MA", "math-integral"],
        ["EL", "earth"]]
      ),
      notes: null,
    };
  },
  mounted() {
    this.fetchNotes().then(notes => {
      this.$data.notes = notes;
      console.log(notes);
    });
  },
  methods: {
    toTitleCase(str: string): string {
      return str.replace(
        /\b\w+/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    },
    async fetchNotes(): Promise<Map<string, Map<string, object[]>>> {
      const data = (await (await fetch(`https://storage.googleapis.com/storage/v1/b/notes.junron.dev/o`))
        .json()).items;
      const out = new Map<string, Map<string, object[]>>();
      for (const item of data) {
        const name = item.name;
        const code = name.substring(0, 2);
        const subject = this.$data.subjects.get(code);
        if (!subject) {
          continue;
        }
        let notes = out.get(code);
        if (!notes) {
          notes = new Map<string, object[]>();
          out.set(code, notes);
        }
        const moduleName = name.split("/")[0];
        let module = notes.get(moduleName);
        if (!module) {
          module = [];
          notes.set(moduleName, module);
        }
        const filename = name.split("/")[1].split(".")[0];
        if (filename.length == 0) {
          continue;
        }
        module.push({
          file: name,
          name: this.toTitleCase(filename).replaceAll("-", " "),
        });
      }
      return out;
    }
  },
});
